export const get = (resource) => fetch(apiUrl(resource), apiOptions('GET'))
    .then(handleErrors)
    .then(result => result.json());

export const post = (resource, payload) => fetch(apiUrl(resource), apiOptions('POST', payload))
    .then(handleErrors);

export const apiOptions = (method, payload) => ({
    method: method,
    withCredentials: true,
    credentials: 'include',
    headers: {
        'Authorization': basicAuthHeader(),
        'Content-Type': 'application/json'
    },
    body: payload ? JSON.stringify(payload) : undefined
});

export const getCredentials = () => ({
    username: localStorage.getItem("COBIRA_USERNAME"),
    password: localStorage.getItem("COBIRA_PASSWORD")
});

export const setCredentials = (username, password) => {
    localStorage.setItem("COBIRA_USERNAME", username);
    localStorage.setItem("COBIRA_PASSWORD", password);
};

export const setAuthenticated = () => localStorage.setItem("COBIRA_AUTHENTICATED", 'true');

export const logout = () => {
    localStorage.removeItem("COBIRA_USERNAME");
    localStorage.removeItem("COBIRA_PASSWORD");
    localStorage.removeItem("COBIRA_AUTHENTICATED");
    navigateRoot();
};

export const isLoggedIn = () => localStorage.getItem("COBIRA_USERNAME")
    && localStorage.getItem("COBIRA_PASSWORD")
    && localStorage.getItem("COBIRA_AUTHENTICATED") === 'true';

export const navigateRoot = () => window.location.href = "/";

export const onEnterKey = (event, callback) => {
    if (event.key === 'Enter') {
        callback();
    }
}

export const apiUrl = (resource) => `${getApiUrl()}/${resource}`;

export const streamUrl = (iccId) => `${getStreamUrl()}/${iccId}?key=${basicAuth()}`

const basicAuthHeader = () => {
    return `Basic ${basicAuth()}`;
};

const basicAuth = () => {
    const {username, password} = getCredentials();
    return `${btoa(username + ':' + password)}`;
}

const handleErrors = (response) => {
    if (!response.ok) {
        throw Error(response);
    }

    return response;
};

export const cmpUrl = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        return 'https://cmp.cobira.co';
    }

    if (process.env.REACT_APP_ENVIRONMENT === "test") {
        return 'https://test.cmp.cobira.co';
    }

    if (process.env.REACT_APP_ENVIRONMENT === "production-simhuis") {
        return 'https://simhuis.cmp.cobira.co';
    }

    return 'http://localhost:8084';
}

const getApiUrl = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production"
        || process.env.REACT_APP_ENVIRONMENT === "test"
        || process.env.REACT_APP_ENVIRONMENT === "production-simhuis"
    ) {
        return `${baseUrl()}/chat-api`;
    }

    return 'http://localhost:8080'
}

const getStreamUrl = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production"
        || process.env.REACT_APP_ENVIRONMENT === "test"
        || process.env.REACT_APP_ENVIRONMENT === "production-simhuis"
    ) {
        return `${baseUrl()}/chat-api/streams`;
    }

    return `${baseUrl()}/streams`;
}

const baseUrl = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        return 'https://chat.cmp.cobira.co';
    }

    if (process.env.REACT_APP_ENVIRONMENT === "test") {
        return 'https://test.chat.cmp.cobira.co';
    }

    if(process.env.REACT_APP_ENVIRONMENT === "production-simhuis") {
        return 'https://chat.simhuis.cmp.cobira.co';
    }

    return 'http://localhost:8080';
}
